import * as React from "react"
import styled from 'styled-components'
/*import ImageEliane from './quiSommesNous/imageEliane'
import ImageBaptiste from './quiSommesNous/imageBaptiste'
import ImageFleche from './quiSommesNous/imageFleche'*/
import { StaticImage } from "gatsby-plugin-image"

import {
  StyledH2,
  StyledSection,
  NumeroSection,
  StyledSousTitre,
  StyledH3,
} from '../StyledBasics'
import {
  theme,
  device,
} from '../GlobalStyle'

const tailleImage = {
  CotePx: `200px`,
  CoteVw: `50vw`,
};

const StyledNumeroSection = styled(NumeroSection)`
  left: 0;
`
const QuiSommesNousLayout = styled.div`
  display: grid;
  grid-template:  "QsnPresentationTitre" auto
                  "QsnPresentationContenu" auto
                  "QsnEquipeTitre" auto
                  "QsnEquipeEliane" auto
                  "QsnEquipeBaptiste" auto / 100%;
  align-items: center;

  @media ${device.tablet} {
    grid-template:  "QsnPresentationTitre QsnEquipeTitre" auto
                    "QsnPresentationContenu QsnEquipeEquipe" auto / 50% 50%;
    position: relative;
  }
`

const PresentationTitre = styled.div`
  grid-area: QsnPresentationTitre;
`

const PresentationContenu = styled.div`
  grid-area: QsnPresentationContenu;
  text-align: justify;
`

const EquipeTitre = styled(StyledH3)`
  grid-area: QsnEquipeTitre;
`

const EquipeEliane = styled.div`
  grid-area: QsnEquipeEliane;
  position : relative;
  @media ${device.tablet} {
    align-self: normal;
    grid-area:  QsnEquipeEquipe;
  }
`
const EquipeBaptiste = styled.div`
  grid-area: QsnEquipeBaptiste;
  position : relative;
  @media ${device.tablet} {
    align-self: normal;
    grid-area:  QsnEquipeEquipe;
  }
`

const TexteEliane = styled.div`
  font-size:1.5rem;
  text-align:left;
  font-family: ${theme.FontTexteEcriture};
  color: ${theme.CouleurPrimaire};
  width : max-content;
  z-index:3;
  margin-left: auto;
  margin-right: auto;
  padding-left: min(calc(${tailleImage.CoteVw} - ( (${tailleImage.CoteVw} + 140px) * 0.3) ),calc(${tailleImage.CotePx} - (${tailleImage.CotePx} * 0.5) ));
  @media ${device.tablet} {
    position: absolute;
    top:0;
    right:0;
    left:0;
    padding-left: 0;
    padding-right: min(calc(${tailleImage.CoteVw} * 1.55 - (${tailleImage.CoteVw}) ),calc(${tailleImage.CotePx} * 2.1 - (${tailleImage.CotePx}) ));
    padding-top: min(calc(${tailleImage.CoteVw} * 1.25 - ${tailleImage.CoteVw} - 3.3rem ),calc(${tailleImage.CotePx} * 1.1 - (${tailleImage.CotePx}) ));
  }
`

const TexteBaptiste = styled.div`
  font-size:1.5rem;
  text-align:left;
  font-family: ${theme.FontTexteEcriture};
  color: ${theme.CouleurPrimaire};
  width : max-content;
  z-index:3;
  margin-left: auto;
  margin-right: auto;
  padding-right: min(calc(${tailleImage.CoteVw} * 1.5 - (${tailleImage.CoteVw}) ),calc(${tailleImage.CotePx} * 1.5 - (${tailleImage.CotePx}) ));
  @media ${device.tablet} {
    position: absolute;
    top:0;
    right:0;
    left:0;
    padding-right: 0;
    padding-left: min(calc(${tailleImage.CoteVw} * 1.55 - (${tailleImage.CoteVw}) ),calc(${tailleImage.CotePx} * 2.1 - (${tailleImage.CotePx}) ));
    padding-top: min(calc(${tailleImage.CoteVw} * 1.6 - (${tailleImage.CoteVw}) ),calc(${tailleImage.CotePx} * 2.2 - (${tailleImage.CotePx}) ));
  }
`
const ImageEquipe = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: ${tailleImage.CoteVw};
  height: ${tailleImage.CoteVw};
  max-width: ${tailleImage.CotePx};
  max-height: ${tailleImage.CotePx};
  @media ${device.tablet} {
    margin-top: 0;
    width: calc(${tailleImage.CoteVw} / 2);
    height: calc(${tailleImage.CoteVw} / 2);
    max-width: ${tailleImage.CotePx};
    max-height: ${tailleImage.CotePx};
  }
`
const LayoutImageBaptiste = styled(ImageEquipe)`
  z-index: 2;
  @media ${device.tablet} {
    padding-left: min(calc(${tailleImage.CoteVw} * 1.37 - (${tailleImage.CoteVw}) ),calc(${tailleImage.CotePx} * 1.75 - (${tailleImage.CotePx}) ));
  }
`

const LayoutImageEliane = styled(ImageEquipe)`
  @media ${device.tablet} {
    padding-right: min(calc(${tailleImage.CoteVw} * 1.37 - (${tailleImage.CoteVw}) ),calc(${tailleImage.CotePx} * 1.75 - (${tailleImage.CotePx}) ));
    padding-top: min(calc(${tailleImage.CoteVw} * 1.37 - (${tailleImage.CoteVw}) ),calc(${tailleImage.CotePx} * 1.75 - (${tailleImage.CotePx}) ));
  }
`

const FlecheBaptiste = styled.div`
  transform: rotateZ(120deg);
  z-index:10;
  position: absolute; 
  width: calc(${tailleImage.CoteVw} / 2);
  max-width: calc(${tailleImage.CotePx} / 2);
  margin-left: max(calc(${tailleImage.CoteVw} - (${tailleImage.CoteVw} * 1.1) ),calc(${tailleImage.CotePx} - (${tailleImage.CotePx} * 1.1) ));
  margin-top: min(calc(${tailleImage.CoteVw} - (${tailleImage.CoteVw} * 0.9) ),calc(${tailleImage.CotePx} - (${tailleImage.CotePx} * 0.9) ));
  @media ${device.tablet} {
    transform: rotateZ(300deg);
    width: calc(${tailleImage.CoteVw} / 4);
    margin-left: min(calc(${tailleImage.CoteVw} * 1.3 - (${tailleImage.CoteVw}) ),calc(${tailleImage.CotePx} * 1.6 - (${tailleImage.CotePx}) ));
    margin-top: min(calc(${tailleImage.CoteVw} * 1.45 - (${tailleImage.CoteVw}) ),calc(${tailleImage.CotePx} * 1.9 - (${tailleImage.CotePx}) ));
  }
`

const FlecheEliane = styled.div`
  transform: rotateZ(300deg);
  z-index:10;
  position: absolute;
  width: calc(${tailleImage.CoteVw} / 2);
  max-width: calc(${tailleImage.CotePx} / 2);
  margin-left: min(calc(${tailleImage.CoteVw} - (${tailleImage.CoteVw} / 3) ),calc(${tailleImage.CotePx} - (${tailleImage.CotePx} / 3) ));
  margin-top: min(calc(${tailleImage.CoteVw} - (${tailleImage.CoteVw} / 4) ),calc(${tailleImage.CotePx} - (${tailleImage.CotePx} / 4) ));
  @media ${device.tablet} {
    transform: rotateZ(120deg);
    width: calc(${tailleImage.CoteVw} / 4);
    margin-left: 0;
    margin-top: 0;
    margin-right: min(calc(${tailleImage.CoteVw} * 1.3 - (${tailleImage.CoteVw}) ),calc(${tailleImage.CotePx} * 1.6 - (${tailleImage.CotePx}) ));
    margin-top: max(calc(-${tailleImage.CoteVw} * 1.1 + (${tailleImage.CoteVw}) ),calc(-${tailleImage.CotePx} * 1.2 + (${tailleImage.CotePx}) ));
  }
`

const ImagePhoto = styled.div`
  border-radius: 50%; 
  overflow: hidden;
  @media ${device.tablet} {
    border-radius: 20%; 
  }
`
const QuiSommesNous = () => {
  /**/
  return (
    <StyledSection id="qui-sommes-nous">
      <StyledNumeroSection>.01</StyledNumeroSection>
      <StyledH2>Qui sommes nous</StyledH2>
      <StyledSousTitre>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</StyledSousTitre>
      <QuiSommesNousLayout>
        <PresentationTitre>
          <StyledH3>Notre passion</StyledH3>
        </PresentationTitre>
        <PresentationContenu>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
        </PresentationContenu>
        <EquipeTitre>Notre équipe</EquipeTitre>
        <EquipeEliane>
          <LayoutImageEliane>
            <FlecheEliane>
              <StaticImage
                width={100}
                alt="Fleche Roxiel"
                placeholder="none"
                src={"../../images/fleche.png"}
                formats={["auto", "jpg", "webp", "avif"]}
              />
            </FlecheEliane>
            <ImagePhoto>
              <StaticImage
                  width={200}
                  height={200}
                  alt="Roxiel Eliane"
                  placeholder="none"
                  src={"../../images/Eliane.png"}
                  formats={["auto", "jpg", "webp", "avif"]}
                />
            </ImagePhoto>
          </LayoutImageEliane>
          <TexteEliane>
            Eliane<br/>
            l'oeil Graphique
          </TexteEliane>
        </EquipeEliane>
        <EquipeBaptiste>
          <TexteBaptiste>
            Baptiste<br/>
            l'oeil technique
          </TexteBaptiste>
          <LayoutImageBaptiste>
            <FlecheBaptiste>
              <StaticImage
                width={100}
                alt="Fleche Roxiel"
                placeholder="none"
                src={"../../images/fleche.png"}
                formats={["auto", "jpg", "webp", "avif"]}
              />
            </FlecheBaptiste>
            <ImagePhoto>
              <StaticImage
                  width={200}
                  height={200}
                  alt="Roxiel Baptiste"
                  placeholder="none"
                  src={"../../images/Baptiste.png"}
                  formats={["auto", "jpg", "webp", "avif"]}
                />
            </ImagePhoto>
          </LayoutImageBaptiste>
        </EquipeBaptiste>
      </QuiSommesNousLayout>
    </StyledSection>
  )
}

export default QuiSommesNous
import * as React from "react"
import styled from 'styled-components'
/*import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"*/
import { StaticImage } from "gatsby-plugin-image"

import {
  StyledH1,
  StyledLink,
} from '../StyledBasics'
import {
  theme,
  device,
} from '../GlobalStyle'

const StyledLinkAccueil = styled(StyledLink)`
  background-color:black;
  @media ${device.laptop} {
    background: none;
  }
`

const Titre = styled.div`
  text-transform: uppercase;
  font-family: ${theme.FontTexteTitre};
  font-weight: ${theme.WeightTexteTitre};
  color: ${theme.CouleurTexteTitre};
  font-size: 1.5rem;
  @media ${device.tablet} {
    font-size: 3rem;
  }
  @media ${device.laptop} {
    text-align: center;
  }
  @media ${device.desktop} {
    font-size: 4rem;
  }
`

const PaternTitre = styled.span`
  display : block;
  @media ${device.laptop} {
    display : inline;
    text-indent: unset;
  }
`

const Titre2 = styled(PaternTitre)`
  text-indent: 5%;
  @media ${device.tablet} {
    text-indent: 15%;
  }
`
const Titre3 = styled(PaternTitre)`
  text-indent: 10%;
  @media ${device.tablet} {
    text-indent: 30%;
  }
`
const Link = styled.div`
  text-align: center;
  font-family: ${theme.FontTexteTitre};
  font-size: 1rem;
  @media ${device.tablet} {
    font-size: 2rem;
  }
  @media ${device.desktop} {
    font-size: 3rem;
  }
`

export const Section = styled.section`
  width: 100%;
  overflow : hidden;
  display:grid;
  min-height: 100vh;
`

export const Contenu = styled.div`
  grid-area: 1/1;
  position: relative;
  display: grid;
  width: 100%;
  min-height: 100vh;
`


const Accueil = ({ switchFormContact , scroll2 }) => {
  return (
		<Section id="accueil">
      
      <StaticImage
        style={{gridArea: "1/1",minHeight: "100vh", opacity: "0.3" }}
        layout="fullWidth"
        alt="Envolez-vous avec Roxiel"
        placeholder="none"
        breakpoints={[480,701,880,1000]}
        src={"../../images/avionold.jpeg"}
        formats={["auto", "jpg", "webp", "avif"]}
        loading="eager"
        fetchpriority="high"
      />
      <Contenu>
        <StyledH1>Agence Technologique</StyledH1>
        <Titre><Titre2>Solution numérique</Titre2> <Titre3>sur mesure</Titre3></Titre>
        <Link><StyledLinkAccueil  href="#qui-sommes-nous">Nous découvrir</StyledLinkAccueil></Link>
      </Contenu>
      
		</Section>
  )
}
export default Accueil


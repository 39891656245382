import * as React from "react"
import styled from 'styled-components'
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  StyledH3,
  StyledH4,
  RondGris, 
} from '../../StyledBasics'
import {
  theme,
  device,
} from '../../GlobalStyle'

const StyledAccordeonContent = styled.div`
  text-align: center;
  overflow: hidden;
  transition: max-height 0.9s;
  max-height: 0;
`

const StyledAccordeonGrid = styled.div`
  padding: 20px 0px 0px 0px;
  display: grid;
  grid-gap: 10px;
  align-items: center;
  grid-template: 
            "OvpTitre" auto
            "OvpContenu" auto
            "OvpImage" auto / 100%;
  @media ${device.tablet} {
    padding: 30px 0px 0px 0px;
    grid-gap: 20px;
    &:nth-child(odd) {
      grid-template:
          "OvpContenu OvpTitre" auto
          "OvpContenu OvpImage" auto / 50% 50%;
    }
    &:nth-child(even) {
      grid-template:
          "OvpTitre OvpContenu" auto
          "OvpImage OvpContenu" auto / 50% 50%;
    }
  }
  @media ${device.laptop} {
    padding: 30px 30px 0px 30px;
    grid-gap: 30px;
  }
`

const StyledAccordeon = styled.div`
  text-align:center;
  & input:checked ~ div { 
    max-height: 200vh; 
  }
  & input:checked ~ label > h3::after { 
    transform: rotate(90deg); 
  }
`

const StyledAccordeonInput = styled.input`
  display: none;
`

const StyledH4Accordeon = styled(StyledH4)`
  z-index: 1;
  position: relative;
  margin:auto;
`
const StyledAccordeonGridTitre = styled.div`
  grid-area: OvpTitre;
`

const StyledAccordeonGridContenu = styled.div`
  grid-area: OvpContenu;
  text-align: justify;
`

const StyledAccordeonGridImage = styled.div`
  grid-area: OvpImage;
`

const StyledAccordeonH3 = styled(StyledH3)`
  background-color: ${theme.CouleurPrimaire};
  border: 1px solid ${theme.CouleurPrimaireLight};
  position: relative;
  margin: 25px 10px 0px 10px;
  padding: 10px 40px 10px 10px;
  cursor: pointer;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  &:hover{
    background-color: white;
    border: 1px solid ${theme.CouleurPrimaire};
    color: ${theme.CouleurPrimaire};
    &:after{
      color: ${theme.CouleurPrimaire};
    }
  }
  &:after {
    color: white;
    display: block;  
    content: "\\25b6";
    position: absolute;
    right: 10px; 
    top: 10px;
    transition: all 0.4s;
  }
  @media ${device.tablet} {
    padding-right: 40px;
  }
  @media ${device.laptop} {
    background: none;
    border: none;
    text-decoration: none;
    color: ${theme.CouleurPrimaire};
    color: ${theme.CouleurPrimaire};
    &:hover{
      background: none;
      border: none;
      color: ${theme.CouleurPrimaireLight};
      &:after{
        color: ${theme.CouleurPrimaireLight};
      }
    }
    &:after {
      top: 0.7rem;
      color: ${theme.CouleurPrimaire};
    }
  }
`

const OvpAccordeon = ({ paramCategorie, paramCategorieTitre  }) => {
  const data = useStaticQuery(graphql`
    query {
      allOvpAccordeonJson(sort: {fields: position, order: ASC}) {
        edges {
          node {
            id
            titre
            contenu
            categorie
            image {
              src
            }
            fields {
              linkImage {
                childImageSharp {
                  gatsbyImageData(width: 300)
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
   <StyledAccordeon>
      <StyledAccordeonInput id={"accordeon-"+paramCategorie} type="checkbox" name="tabr"/>
      <label htmlFor={"accordeon-"+paramCategorie}><StyledAccordeonH3>{ paramCategorieTitre }</StyledAccordeonH3></label>
      <StyledAccordeonContent>
         {data.allOvpAccordeonJson.edges.filter(edge => edge.node.categorie === paramCategorie).map(filterEdge => (
          <StyledAccordeonGrid key={filterEdge.node.id}>
            <StyledAccordeonGridTitre>
              <StyledH4Accordeon><RondGris/>{filterEdge.node.titre}</StyledH4Accordeon>
            </StyledAccordeonGridTitre>
            <StyledAccordeonGridContenu>{filterEdge.node.contenu}</StyledAccordeonGridContenu>
            <StyledAccordeonGridImage>
              <GatsbyImage image={getImage(filterEdge.node.fields.linkImage)} alt={filterEdge.node.titre+" - Roxiel"}/>
            </StyledAccordeonGridImage>
          </StyledAccordeonGrid>
          ))}
      </StyledAccordeonContent>
    </StyledAccordeon>
  )
}

export default OvpAccordeon
import * as React from "react"
import styled from 'styled-components'
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  StyledH3,
} from '../../StyledBasics'
import {
  theme,
  device,
} from '../../GlobalStyle'

const StyledAccordeonContent = styled.div`
  text-align: center;
  overflow: hidden;
  transition: max-height 0.4s;
  max-height: 0;
  @media ${device.tablet} {
    top: 50%;
    transform: translate(0, -50%);
    right:0;
    width: calc(100% - max(40%,300px));
    position:absolute;
    display: grid;
    grid-template-columns: auto;
    align-items: center;
    gap: 20px;
  }
`

const StyledAccordeonInput = styled.input`
  display: none;
`

const StyledAccordeonH3 = styled(StyledH3)`
  cursor: pointer;
  max-width:250px;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 10px;
  padding: 10px ;
  background-color: ${theme.CouleurPrimaire};
  border: 1px solid ${theme.CouleurPrimaireLight};
  &:after{
    display:block;
    content : "";
    position: absolute;
    top: calc(50%);
    left: calc(50%);
    transform: translate(-50%, -50%);
    width:250px;
    height  : 100%;
    background-color: none;
    border: none;
    transition: 0.4s;
  }
  @media ${device.laptop} {
    color: ${theme.CouleurPrimaire};
    background: none;
    border: none;
    &:hover{
      color: ${theme.CouleurPrimaireLight};
    }
  }
`

const StyledLabel = styled.label`
  position: relative;
`

const StyledAccordeon = styled.div`
  text-align:center;
  display: grid;
  align-items: center;
  grid-template-columns: auto;
  @media ${device.tablet} {
    grid-template-columns: max(40%,300px) calc(100% - max(40%,300px));
  }
  & input:checked ~ div > div { 
    max-height: 200vh; 
  }
  & input ~ div > label > h3 { 
    &:after{
      border-radius:0;
    }
  }
  & input:checked ~ div > label > h3 { 
    color: ${theme.CouleurPrimaire};
    background: none;
    border: none;
    line-height  : 200px;
    &:after{
      z-index: -1;
      width:200px;
      height  : 200px;
      border-radius:50%;
      background-color: white;
      border: 1px solid ${theme.CouleurPrimaire};
    }
    @media ${device.laptop} {
      &:after{
        border: none;
        background: ${theme.CouleurLigneLink2};
      }
      &:hover{
        color: ${theme.CouleurPrimaireLight};
      }
    }
  }
`
const Container = styled.div`
  position:relative;
`

const StyledMenu = styled.div`
  @media ${device.tablet} {
    min-width:300px;
  }
`
const StyledAccordeonContenu = styled.div`
  text-align: justify;
`

const CofAccordeon = () => {
  const data = useStaticQuery(graphql`
    query {
      allCofAccordeonJson(sort: {fields: position, order: ASC}) {
        edges {
          node {
            id
            titre
            contenu
            position
            image {
              src
            }
            fields {
              linkImage {
                childImageSharp {
                  gatsbyImageData(width: 300)
                }
              }
            }
          }
        }
      }
    }
  `)
  return (
    <Container>
      {data.allCofAccordeonJson.edges.map((edge , index) => (
          <StyledAccordeon key={edge.node.id}>
            <StyledAccordeonInput id={"accordeon-"+edge.node.id} type="radio" name="tabr" defaultChecked={index === 0}/>
            <StyledMenu>
              <StyledLabel htmlFor={"accordeon-"+edge.node.id}><StyledAccordeonH3 id={"accordeon-contenu-"+edge.node.id}>{edge.node.titre}</StyledAccordeonH3></StyledLabel>
            </StyledMenu>
            <div>
                <StyledAccordeonContent>
                  <div>
                    <GatsbyImage image={getImage(edge.node.fields.linkImage)} alt={edge.node.titre+" - Roxiel"}/>
                  </div>
                  <StyledAccordeonContenu>
                    {edge.node.contenu}
                  </StyledAccordeonContenu>
                </StyledAccordeonContent>
            </div>
          </StyledAccordeon>
      ))}
    </Container>
  )
}

export default CofAccordeon
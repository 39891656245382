import * as React from "react"
import styled from 'styled-components'
import Accordeon from './onVousPropose/Accordeon';
import IconNosRealisation from '../../images/IconNosRealisation.gif'

import {
  StyledH2,
  StyledSection,
  NumeroSection,
  StyledSousTitre,
  StyledLink2,
  StyledDivRondGris2,
  RondGris2,
  TraitRondGris2,
} from '../StyledBasics'

import {
  deviceReverse,
} from '../GlobalStyle'

const StyledNumeroSection = styled(NumeroSection)`
  right: 0;
`
const StyledRondGris2 = styled(RondGris2)`
  left: calc(50% + 130px);
  top: 50%;
  transform: translate(-50%, -50%);
`
const StyledDivIcon = styled.div`
  position : absolute;
  left: calc(50% + 130px);
  top: 50%;
  transform: translate(-50%, -50%);
  opacity:0.3;
`
const StyledTraitRondGris2 = styled(TraitRondGris2)`
  top: calc(50% + 16px);
  left: calc(50% + 85px);
`

const OnVousPropose = ({scroll5}) => {
  return (
    <StyledSection id="on-vous-propose">
      <StyledNumeroSection>.02</StyledNumeroSection>
      <StyledH2>On vous propose</StyledH2>
      <StyledSousTitre>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</StyledSousTitre>
      <Accordeon paramCategorie="DevWeb" paramCategorieTitre="Développement web"/>
      <Accordeon paramCategorie="Graphisme" paramCategorieTitre="Graphisme"/>
      <Accordeon paramCategorie="ConEx" paramCategorieTitre="Conseil & expertise"/>
      <StyledDivRondGris2>
        <StyledLink2 href="#realisation">
          Nos résalisation
          <StyledDivIcon>
             <picture>
              <source srcSet="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" media={deviceReverse.laptop}/> 
              <img src={IconNosRealisation} alt="" width="63" height="63" loading="lazy"/>
            </picture>
          </StyledDivIcon>
          <StyledRondGris2/>
          <StyledTraitRondGris2/>
        </StyledLink2>
      </StyledDivRondGris2>
    </StyledSection>
  )
}

export default OnVousPropose
import * as React from "react"
import styled from 'styled-components'
import Accordeon from './CommentFaitOn/Accordeon';

import {
  StyledH2,
  StyledSection,
  NumeroSection,
  StyledSousTitre,
} from '../StyledBasics'

const StyledNumeroSection = styled(NumeroSection)`
  left: 0;
`
  
const CommentFaitOn = () => {
  return (
    <StyledSection id="comment-fait-on">
      <StyledNumeroSection>.03</StyledNumeroSection>
      <StyledH2>Comment Fait-on</StyledH2>
      <StyledSousTitre>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</StyledSousTitre>
      <Accordeon/>
    </StyledSection>
  )
}

export default CommentFaitOn
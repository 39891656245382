import styled from 'styled-components'
import {
  theme,
  device,
} from './GlobalStyle'

export const StyledInputTexte= styled.textarea`
  all: unset;
  align-self: center;
  margin: 10px 12px;
  background-image: none;
  color: ${theme.CouleurTexteInput};
  border: none;
  border-bottom: 3px solid ${theme.CouleurPrimaire};
  border-top: 3px solid ${theme.CouleurPrimaire};
  font-family: ${theme.FontTexte};
  font-style: normal;
  line-height: 1.2rem;
  font-size: 1.2rem;
  width:90%;
  @media ${device.tablet} {
    width:70%;
  }
  &:focus{
    border: 3px solid ${theme.CouleurPrimaire};
  }
  background-color: transparent;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${theme.CouleurPlaceholderInput};
  }
  :-ms-input-placeholder {
     color: ${theme.CouleurPlaceholderInput};
  }
`

export const NumeroSection= styled.div`
  z-index:-1;
  position : absolute;
  font-family: ${theme.FontTexteTitre};
  font-size: 10rem;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: ${theme.CouleurTexteNumeroSection};
  margin: 0px;
`
export const StyledSection = styled.section`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: ${theme.MaxWidthSection};
  overflow : hidden;
`
export const StyledDivCenter=styled.div`
  text-align:center;
`

export const StyledH1 = styled.h1`
  all: unset;
  display:block;
  width: fit-content;
  font-size: 2rem;
  text-transform: uppercase;
  font-family: ${theme.FontTexteTitre};
  font-weight: ${theme.WeightTexteTitre};
  color: ${theme.CouleurTexteTitre};
  margin: 10px;
  @media ${device.tablet} {
    margin: 1rem;
    font-size: 3rem;
  }
  @media ${device.laptop} {
    margin: 2rem;
    font-size: 4rem;
  }
  @media ${device.desktop} {
    letter-spacing: 0.05em;
  }
  &:after {
    margin-top:-0.5rem;
    display:block;
    clear:both;
    content : "";
    position: relative;
    left    : 0;
    bottom  : 0;
    width:250px;
    max-width:30%;
    height  : 1px;
    border-bottom:3px solid ${theme.CouleurPrimaire};
  }
`
export const StyledH2 = styled.h2`
  all: unset;
  display:block;
  width: fit-content;
  font-size: 2rem;
  text-transform: uppercase;
  margin: 2rem;
  font-family: ${theme.FontTexteTitre};
  font-weight: ${theme.WeightTexteTitre};
  color: ${theme.CouleurTexteTitre};
  @media ${device.tablet} {
    font-size: 3rem;
  }
  @media ${device.laptop} {
    font-size: 4rem;
  }
  @media ${device.desktop} {
    letter-spacing: 0.05em;
  }
  &:after {
    margin-top:-0.5rem;
    margin-left: 2rem;
    display:block;
    clear:both;
    content : "";
    position: relative;
    left    : 0;
    bottom  : 0;
    width:250px;
    max-width:30%;
    height  : 1px;
    border-bottom:3px solid ${theme.CouleurPrimaire};
  }
`

export const StyledH3 = styled.h3`
  all: unset;
  display:block;
  font-size: 1.5rem;
  text-align: center;
  margin: 1rem;
  font-family: ${theme.FontTexteTitre};
  font-weight: ${theme.WeightTexteTitre};
  color: ${theme.CouleurTexteTitre};
  @media ${device.tablet} {
    font-size: 1.75rem;
  }
  @media ${device.laptop} {
    font-size: 2rem;
  }
`

export const StyledH4 = styled.h4`
  all: unset;
  display:block;
  width: fit-content;
  font-size: 1.5rem;
  text-align: center;
  margin: 1rem auto;
  font-family: ${theme.FontTexteTitre};
  font-weight: ${theme.WeightSousTitre};
  color: ${theme.CouleurTexteTitre};
  @media ${device.tablet} {
    font-size: 1.75rem;
  }
  @media ${device.laptop} {
    font-size: 2rem;
  }
  &:after {
    display:block;
    clear:both;
    content : "";
    position: relative;
    left    : 0;
    bottom  : 0;
    max-width:30%;
    height  : 1px;
    border-bottom:2px solid ${theme.CouleurPrimaire};
    margin-top:-4px;
    @media ${device.tablet} {
      margin-top:-5px;
    }
    @media ${device.laptop} {
      margin-top:-6px;
    }
  }
`

export const StyledSousTitre = styled.div`
  font-family: ${theme.FontTexte};
  font-weight: ${theme.WeightSousTitre};
  color: ${theme.CouleurTexteSousTitre};
  font-style: normal;
  line-height: 1.2rem;
`

export const StyledLink = styled.a`
  all: unset;
  cursor:pointer;
  background-color: ${theme.CouleurPrimaire};
  border: 1px solid ${theme.CouleurPrimaireLight};
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  &:hover{
    background-color: white;
    border: 1px solid ${theme.CouleurPrimaire};
    color: ${theme.CouleurPrimaire};
  }
  @media ${device.laptop} {
    background: none;
    border: none;
    color: ${theme.CouleurPrimaire};
    padding: 0;
    display: inline;
    text-decoration: none;
    color: ${theme.CouleurPrimaire};
    &:hover{
      background: none;
      border: none;
      color: ${theme.CouleurPrimaireLight};
    }
  }
`
export const StyledButton = styled.button`
  all: unset;
  cursor:pointer;
  background-color: ${theme.CouleurPrimaire};
  border: 1px solid ${theme.CouleurPrimaireLight};
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  width: fit-content;
  align-self:center;
  &:hover{
    background-color: white;
    border: 1px solid ${theme.CouleurPrimaire};
    color: ${theme.CouleurPrimaire};
  }
`

export const StyledLink2 = styled.a`
  all: unset;
  margin-top: 10px;
  font-family: ${theme.FontTexteTitre};
  font-weight: ${theme.WeightTexteTitre};
  background-color: ${theme.CouleurPrimaire};
  border: 1px solid ${theme.CouleurPrimaireLight};
  color: white;
  padding: 5px 10px;
  &:hover{
    background-color: white;
    border: 1px solid ${theme.CouleurPrimaire};
    color: ${theme.CouleurPrimaire};
  }
  cursor:pointer;
  text-align: center;
  display: inline-block;
  font-size: 1.5rem;
  @media ${device.laptop} {
    background: none;
    border: none;
    color: ${theme.CouleurTexteLink2};
    padding: 0;
    display: inline;
    &:hover{
      background: none;
      border: none;
      color: ${theme.CouleurTexteOverLink2};
    }
  }
`

export const RondGris = styled.div`
  z-index: -1;
  position: absolute;
  background-color: ${theme.CouleurRondGris};
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
  top: -1rem;
  @media ${device.tablet} {
    height: 4rem;
    width: 4rem;
    top: -1.25rem;
  }
  @media ${device.laptop} {
    height: 5rem;
    width: 5rem;
    top: -1.5rem;
  }
`
export const StyledDivRondGris2 = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  @media ${device.laptop} {
    height: max(3rem,63px);
  }
`

export const StyledDivRealtive = styled.div`
  position: relative;
`

export const RondGris2 = styled.div`
  @media ${device.laptop} {
    z-index: -1;
    position: absolute;
    background-color: ${theme.CouleurRondGris};
    border-radius: 50%;
    height: 3rem;
    width: 3rem;
  }
`
export const TraitRondGris2 = styled.div`
  @media ${device.laptop} {
    z-index: -1;
    position: absolute;
    width:150px;
    max-width:100%;
    height  : 1px;
    border-bottom:3px solid ${theme.CouleurTraitRondGris};
    margin-left: auto;
    margin-right: 50%;
    transform: translate(-50%, -50%);
  }
`
import * as React from "react"
import styled from 'styled-components'
/*import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"*/
import { StaticImage } from "gatsby-plugin-image"
import { useRef } from "react";
//import emailjs from '@emailjs/browser';

import {
	StyledH2,
	StyledInputTexte,
	StyledButton,
} from '../StyledBasics'
import {
	device,
} from '../GlobalStyle'

const StyledH2Contact = styled(StyledH2)`
	color:white;
	z-index:2;
`

const StyledInputTexteContact = styled(StyledInputTexte)`
	z-index:2;
	color:white;
`

const StyledButtonContact = styled(StyledButton)`
	z-index:2;
	background-color:black;
`

/*const StyledImageContact = styled.div`
	width:min(150px,calc(50% - 5px));
	opacity:0.3;
	border-radius:30%;
	overflow:hidden;
`*/

const StyledGridImages = styled.div`
	z-index:-1;
	position:absolute;
  	display: flex;
  	text-align:center;
  	justify-content: space-around;
  	align-items: center;
  	gap: 5px;
  	left:0;
  	right:0;
  	top:0;
  	bottom:0;
  	@media ${device.tablet} {
    	gap: 10px;
  	}
`
const StyledContenu = styled.div`
	position:relative;
	text-align: justify;
	border:3px solid rgba(14, 126, 232, 0.5);
	width:min(70%, 1140px * 0.7);
	display:flex;
	flex-direction: column;
	margin: 20px auto;
	padding:20px;
`
const StyledFormGrid = styled.div`
	display:flex;
	flex-direction: column;
`
const Contact = ({switchFormContact}) => {
	/*const data = useStaticQuery(graphql`
		query {
		  allFile(filter: {relativePath: {regex : "/Eliane.png|Baptiste.png/"}}) {
		    nodes {
		      childImageSharp {
		        gatsbyImageData(width: 150)
		      }
		    }
		  }
		}
	`)*/
	const form = useRef();

	const sendEmail = (e) => {
		e.preventDefault();

		/*emailjs.sendForm('mailjet', 'roxiel', form.current, 'cS7CP_uhPm4hf_AWp')
			.then((result) => {
			    console.log(result.text);
			}, (error) => {
			    console.log(error.text);
			}
		);
		{data.allFile.nodes.map((node , index) => (
		      		<StyledImageContact key={index}>
		      			<GatsbyImage image={getImage(node)} alt="Contactez-nous Roxiel enfant"/>
		      		</StyledImageContact>
		         ))}*/
		switchFormContact()
	};

	return (
		<StyledContenu>
			<StyledGridImages>
				<StaticImage
					style={{
			        	opacity: "0.3",
			        	width:"min(150px,calc(50% - 5px))",
						borderRadius:"30%",
						overflow:"hidden"
					}}
			        width={150}
      				height={150}
			        alt="Contactez-nous Roxiel enfant Eliane"
			        placeholder="none"
			        src={"../../images/Eliane.png"}
			        formats={["auto", "jpg", "webp", "avif"]}
			      />
				<StaticImage
			        style={{
			        	opacity: "0.3",
			        	width:"min(150px,calc(50% - 5px))",
						borderRadius:"30%",
						overflow:"hidden"
					}}
			        width={150}
      				height={150}
			        alt="Contactez-nous Roxiel enfant Baptiste"
			        placeholder="none"
			        src={"../../images/Baptiste.png"}
			        formats={["auto", "jpg", "webp", "avif"]}
			      />
		      	
		    </StyledGridImages>
		  	<StyledH2Contact>Contactez-nous</StyledH2Contact>
		  	<form ref={form} onSubmit={sendEmail}>
		  		<StyledFormGrid>
					<StyledInputTexteContact name="message" type="text" rows="7" placeholder="Ecrivez nous un message et n'oubliez pas vos coordonnées :)"/>
		  			<StyledButtonContact>Envoyer</StyledButtonContact>
		  		</StyledFormGrid>
		  	</form>
		</StyledContenu>
	)
}

export default Contact
export const theme = {
  MaxWidthSection : `1140px`,
  FontTexteTitre: `Aldrich`,
  FontTexteEcriture: `Indie Flower`,
  FontTexte: `Sen`,
  WeightTexteTitre: `700`,
  WeightSousTitre: `600`,
  CouleurTexteTitre: `#292e33`,
  CouleurTexteSousTitre: `#344050`,
  CouleurTexteNumeroSection: `#f2f2f2`,
  CouleurTexteLink2: `#344050`,
  CouleurTexteOverLink2: `#b3b7bb`,
  CouleurBorderInput: `#b3b7bb`,
  CouleurPlaceholderInput: `#b3b7bb`,
  CouleurTexteInput: `#344050`,
  CouleurLigneLink2: `#e1e1e1`, 
  CouleurPrimaire: `#0e7ee8`,
  CouleurRondGris: `#EBECF0`,
  CouleurTraitRondGris: `#e1e1e1`,
  CouleurPrimaireLight : `#b1d2f0`,
  test :`allOvpdeveloppementJson`,
};

export const device = {
  mobile: `(min-width: 320px)`,
  tablet: `(min-width: 768px)`,
  laptop: `(min-width: 1024px)`,
  desktop: `(min-width: 1440px)`,
};

export const deviceReverse = {
  mobile: `(max-width: 320px)`,
  tablet: `(max-width: 768px)`,
  laptop: `(max-width: 1024px)`,
  desktop: `(max-width: 1440px)`,
};
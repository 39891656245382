import * as React from "react"
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby';
import IconNosRealisation from '../../images/IconIncognito.gif'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import {
  StyledH2,
  StyledH3,
  StyledSection,
  NumeroSection,
  StyledSousTitre,
  StyledLink,
  StyledDivRondGris2,
  RondGris2,
  TraitRondGris2,
  StyledDivCenter,
  StyledH4,
} from '../StyledBasics'

import {
  theme,
  device,
  deviceReverse,
} from '../GlobalStyle'

const StyledGridProjets = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align:center;
  @media ${device.tablet} {
    gap: 20px;
  }
`
const StyledGridImages = styled.div`
  display: flex;
  text-align:center;
  justify-content: center;
  align-items: center;
  gap: 5px;
  @media ${device.tablet} {
    gap: 10px;
  }
`
const StyledNumeroSection = styled(NumeroSection)`
  right: 0;
`

const StyledDivIcon = styled.div`
  position : absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity:0.65;
`
const StyledRondGris2 = styled(RondGris2)`
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`
const StyledTraitRondGris2 = styled(TraitRondGris2)`
  width:30%;
  top: calc(50% + 11px);
  left: 50%;
`
const StyledItemProjet  = styled.div`
  width:100%;
  @media ${device.tablet} {
    width: calc(50% - 10px);
  }
  @media ${device.laptop} {
    width: calc(33% - 15px);
  }
`
const TexteEcriture = styled(StyledH4)`
  font-family: ${theme.FontTexteEcriture};
  color: ${theme.CouleurPrimaire};
  font-size: 1.5rem;
`
const StyledContenu = styled.div`
  text-align: justify;
`

const CeQuOnAFait = ({openContact}) => {
  const data = useStaticQuery(graphql`
    query {
      allCqfProjetJson(sort: {fields: position, order: ASC}) {
        edges {
          node {
            id
            titre
            contenu
            position
            fields {
              linkImage {
                childImageSharp {
                  gatsbyImageData(width: 300)
                }
              }
              linkImagesTechnologie {
                childImageSharp {
                  gatsbyImageData(width: 50)
                }
              }
            }
          }
        }
      }
    }
  `)
  return (
    <StyledSection id="realisation">
      <StyledNumeroSection>.04</StyledNumeroSection>
      <StyledH2>Ce qu'on a déjà réalisé</StyledH2>
      <StyledSousTitre>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</StyledSousTitre>
      <StyledGridProjets>
        {data.allCqfProjetJson.edges.map(edge => (
          <StyledItemProjet key={edge.node.id}>
            <GatsbyImage image={getImage(edge.node.fields.linkImage)} alt={edge.node.titre+" - Roxiel"}/>
            <StyledH3>{edge.node.titre}</StyledH3> 
            <StyledContenu>{edge.node.contenu}</StyledContenu>
            <TexteEcriture>Outils / Technos</TexteEcriture>
            <StyledGridImages>
              {edge.node.fields.linkImagesTechnologie.map((linkImageTechnologie , index) => (
                <div key={index}>
                  <GatsbyImage image={getImage(linkImageTechnologie)} alt="Technologie Roxiel"/>
                </div>
              ))}
            </StyledGridImages>      
          </StyledItemProjet>
        ))}
      </StyledGridProjets>
      <StyledDivRondGris2>
        <StyledDivIcon>
           <picture>
            <source srcSet="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" media={deviceReverse.laptop}/> 
            <img src={IconNosRealisation} alt="" width="63" height="63" loading="lazy"/>
          </picture>
        </StyledDivIcon>
        <StyledRondGris2/>
        <StyledTraitRondGris2/>
      </StyledDivRondGris2>
      <StyledDivCenter>
        <StyledLink href="#section-form-contact" onClick={openContact}>Et plus de projet en 'marque blanche'...</StyledLink>
      </StyledDivCenter>
      <StyledDivCenter>
        <StyledH3><StyledLink href="#section-form-contact" onClick={openContact}>Contactez-nous</StyledLink></StyledH3>
      </StyledDivCenter>
    </StyledSection>
  )
}

export default CeQuOnAFait